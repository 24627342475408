//  Region Import External Lib (e.g React, etc)

//  Region Import Constants

//  Region Import Interfaces
import { CustomButtonProps } from "./CustomButton.types";

//  Region Import Redux Related/Hooks

//  Region Import Utility Function

//  Region Import Components

//  Region Import Assets

//  Region Import Style
import {
  CustomButtonPrimary,
  CustomButtonDanger,
  CustomButtonDisabled,
  CustomButtonContent,
  CustomButtonSuccess,
  CustomButtonSecondary,
} from "./CustomButton.styles";

export default function CustomButton({
  customButtonModel = "primary",
  customButtonVariant = "contained",
  customButtonSize = "medium",
  buttonProps,
  boxProps,
  children,
}: CustomButtonProps) {
  /**
   * wrap your component with BasedComponent
   * if you think the component might be broken when doing rendering.
   *
   * If it is just a static component, it might not necessary to wrap your component
   * with BasedComponent
   */
  let ButtonComponent = CustomButtonPrimary;

  if (customButtonModel === "secondary")
    ButtonComponent = CustomButtonSecondary;
  if (customButtonModel === "danger") ButtonComponent = CustomButtonDanger;
  if (customButtonModel === "success") ButtonComponent = CustomButtonSuccess;

  if (buttonProps?.disabled) ButtonComponent = CustomButtonDisabled;

  return (
    <ButtonComponent
      customButtonModel={customButtonModel}
      customButtonSize={customButtonSize}
      variant={customButtonVariant}
      {...buttonProps}
    >
      <CustomButtonContent
        {...boxProps}
        sx={{
          ...(customButtonVariant === "contained" &&
            customButtonModel === "primary" && {
              color: "secondary.main",
            }),
        }}
      >
        {children}
      </CustomButtonContent>
    </ButtonComponent>
  );
}
