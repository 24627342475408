import { ThemeOptions, createTheme } from "@mui/material";
import "./fonts.scss";

const themeSettings: ThemeOptions = {
  palette: {
    primary: {
      main: "#5ef9f6",
      light: "#5ef9f690",
    },
    secondary: {
      main: "#282828",
      light: "#28282820",
    },
    tertiary: {
      main: "#5EAFF9",
      light: "#5EAFF980",
    },
    text: {
      primary: "#5ef9f6",
      secondary: "#ffffff",
      disabled: "#d3d3d3",
    },
    background: {
      default: "#282828",
      paper: "#1f1f1f",
    },
    info: {
      main: "#cfd2d4",
    },
    warning: {
      main: "#FFD027",
    },
    success: {
      main: "#38ee78",
    },
    error: {
      main: "#ED2912",
    },
    common: {
      black: "#000000",
      white: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Montserrat-Regular"].join(","),
    h1: {
      fontFamily: ["Montserrat-SemiBold"].join(","),
      fontSize: "2rem",
    },
    h2: {
      fontFamily: ["Montserrat-SemiBold"].join(","),
      fontSize: "1.5rem",
    },
    h3: {
      fontFamily: ["Montserrat-Medium"].join(","),
      fontSize: "1.25rem",
    },
    h4: {
      fontFamily: ["Montserrat-Medium"].join(","),
      fontSize: "1rem",
    },
    h5: {
      fontFamily: ["Montserrat-Regular"].join(","),
      fontSize: "0.875rem",
    },
    h6: {
      fontFamily: ["Montserrat-Regular"].join(","),
      fontSize: "0.6875rem",
    },
  },
};

const theme = createTheme(themeSettings);

export default theme;
