import Menu from "@mui/icons-material/Menu";
import AppBar, { AppBarProps } from "@mui/material/AppBar";
import Box, { BoxProps } from "@mui/material/Box";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import Toolbar, { ToolbarProps } from "@mui/material/Toolbar";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { alpha, styled } from "@mui/material/styles";
import { rem, vw, vwMobile, vwTab } from "theme/muiSizes";

export const CustomAppBar = styled(AppBar)<AppBarProps>(({ theme }) => ({
  minHeight: "70px",
  height: "auto",
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: `0px 1px 15px 0px ${
    theme.palette.primary.light
  }, 0px 3px 40px 0px ${
    theme.palette.primary.light
  }, 0px 5px 100px 0px  ${alpha(theme.palette.primary.light, 0.2)}`,
  // boxShadow: "none",
  animationName: "enter-navbar",
  animationDuration: "0.8s",
  animationTimingFunction: "ease-out",
  animationPlayState: "running",
  "@keyframes enter-navbar": {
    "0%": {
      opacity: 0,
      transform: "translateY(-200%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
  [theme.breakpoints.down(440)]: {
    minHeight: vwMobile(70),
    boxShadow: `0px ${vwMobile(1)} ${vwMobile(15)} 0px ${
      theme.palette.primary.main
    }, 0px ${vwMobile(3)} ${vwMobile(40)} 0px ${
      theme.palette.primary.light
    }, 0px ${vwMobile(5)} ${vwMobile(100)} 0px  ${alpha(
      theme.palette.text.primary,
      0.2
    )}`,
  },
}));

export const CustomToolbar = styled(Toolbar)<ToolbarProps>(({ theme }) => ({
  height: "65px",
  display: "flex",
  paddingTop: "5px",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.up(600)]: {
    paddingLeft: "50px",
    paddingRight: "24px",
  },
  [theme.breakpoints.down(1200)]: {
    paddingRight: "50px",
  },
  [theme.breakpoints.down(700)]: {
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  [theme.breakpoints.down(440)]: {
    paddingLeft: vwMobile(20),
    paddingRight: vwMobile(20),
    minHeight: vwMobile(55),
    height: vwMobile(65),
  },
}));

export const CustomChildToolbar = styled(Toolbar)<ToolbarProps>(
  ({ theme }) => ({
    height: "100%",
    paddingLeft: "100px !important",
    paddingRight: "100px !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down(700)]: {
      paddingLeft: "50px !important",
      paddingRight: "50px !important",
    },
    [theme.breakpoints.down(440)]: {
      paddingLeft: `${vwMobile(40)} !important`,
      paddingRight: `${vwMobile(40)} !important`,
      minHeight: vwMobile(55),
    },
  })
);

export const ImageCustom = styled("img")(({ theme }) => ({
  height: "47px",
  [theme.breakpoints.down(440)]: {
    height: vwMobile(47),
  },
}));

export const Search = styled(Box)<BoxProps>(({ theme }) => ({
  position: "relative",
  borderRadius: "10px",
  backgroundColor: theme.palette.secondary.main,
  width: "100%",
  [theme.breakpoints.down(600)]: {
    marginTop: "20px",
    marginBottom: "5px",
    width: vwTab(690),
  },
  [theme.breakpoints.down(440)]: {
    marginTop: vwMobile(20),
    marginBottom: vwMobile(5),
    borderRadius: vwMobile(10),
  },
}));

export const SearchIconWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  // padding: theme.spacing(0, 2),
  paddingLeft: rem(12),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 3,
}));

export const StyledInputBase = styled(InputBase)<InputBaseProps>(
  ({ theme }) => ({
    zIndex: 2,
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.secondary.main,
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingLeft: "48px",
      transition: theme.transitions.create("width"),
      width: "380px",
      borderRadius: "10px",
      color: theme.palette.text.secondary,
      [theme.breakpoints.down(1000)]: {
        width: vw(500),
      },
      [theme.breakpoints.down(600)]: {
        width: vwTab(630),
      },
      [theme.breakpoints.down(440)]: {
        paddingTop: vwMobile(10),
        paddingBottom: vwMobile(10),
        paddingLeft: vwMobile(48),
        borderRadius: vwMobile(10),
        width: vwMobile(280),
      },
    },
    "& .MuiInputBase-input:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.4),
      transition: "all 0.3s",
    },
  })
);

export const NavbarMenuWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down(1200)]: {
    flexDirection: "column",
    paddingBottom: "20px",
  },
  [theme.breakpoints.down(440)]: {
    paddingBottom: vwMobile(20),
  },
}));

export const NavbarMenu = styled(Typography)<TypographyProps>(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: "15px",
  marginRight: "35px",
  color: theme.palette.text.secondary,
  "&:hover": {
    cursor: "pointer",
    transition: "all 0.3s",
  },
  [theme.breakpoints.down(1200)]: {
    marginTop: "25px",
  },
  [theme.breakpoints.down(440)]: {
    fontSize: vwMobile(15),
    marginRight: vwMobile(35),
    marginTop: vwMobile(25),
  },
}));

export const MenuIconButton = styled(IconButton)<IconButtonProps>(
  ({ theme }) => ({
    width: "45px",
    height: "45px",
    "&:hover": {
      backgroundColor: "#ffffff30",
    },
    [theme.breakpoints.down(440)]: {
      width: vwMobile(45),
      height: vwMobile(45),
    },
  })
);

export const MenuIcon = styled(Menu)(({ theme }) => ({
  fontSize: "27px",
  color: theme.palette.common.white,
  [theme.breakpoints.down(440)]: {
    fontSize: vwMobile(27),
  },
}));

export const ListSearchMenu = styled(Box)<BoxProps>(({ theme }) => ({
  position: "absolute",
  minHeight: "50px",
  height: "auto",
  maxHeight: "300px",
  width: "428px",
  backgroundColor: theme.palette.background.paper,
  boxSizing: "border-box",
  overflow: "auto",
  top: "50px",
  left: 0,
  borderRadius: rem(10),
  boxShadow: `${rem(1)} ${rem(3)} ${rem(8)} ${
    theme.palette.primary.main
  }, ${rem(3)} ${rem(6)} ${rem(10)} 0px ${theme.palette.primary.light}`,
  [theme.breakpoints.down(1000)]: {
    width: vw(580),
  },
  [theme.breakpoints.down(840)]: {
    width: vw(590),
  },
  [theme.breakpoints.down(600)]: {
    width: vwTab(690),
    top: "80px",
  },
  [theme.breakpoints.down(440)]: {
    top: vwMobile(80),
    minHeight: vwMobile(50),
    maxHeight: vwMobile(300),
  },
}));

export const ListSearchMenuItem = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  color: theme.palette.text.primary,
  padding: `${rem(10)} ${rem(20)}`,
  cursor: "pointer",
  boxSizing: "border-box",
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
}));

export const ImageBrand = styled("img")(({ theme }) => ({
  width: rem(50),
  objectFit: "scale-down",
  marginRight: rem(15),
}));
